<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0">
            <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                <li class="nav-item">
                    <a class="nav-link fw-bolder" :class="{'active': tab == 'item'}" :aria-disabled="loadingTable" data-bs-toggle="tab" href="#item" @click="(tab = 'item')">ITEM</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link fw-bolder" :class="{'active': tab == 'desain'}" :aria-disabled="loadingTable" data-bs-toggle="tab" href="#desain" @click="(tab = 'desain')">DESAIN</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link fw-bolder" :class="{'active': tab == 'display'}" :aria-disabled="loadingTable" data-bs-toggle="tab" href="#display" @click="(tab = 'display')">DISPLAY</a>
                </li>
            </ul>
        </div>
        <div class="card-body tab-content">
            <div class="tab-pane fade" :class="tab == 'item' ? 'show active' : ''" id="item" role="tabpanel">
                <div class="mb-7">
                    <h4 class="mb-7 text-gray-700">Cari Artikel</h4>
                    <div class="row g-5">
                        <label class="required fs-6 fw-bold mb-2">Artikel</label>
                        <div class="row">
                            <div class="col-auto">
                                <input type="text" class="form-control w-200px" name="artikel" v-model="tempData.artikel" placeholder="Input artikel" v-on:keyup.enter="getDetailArtikel()" />
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-light-primary" @click="getDetailArtikel()">Cari</button>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-light-primary" @click="getArtikel(), pagination.page = 1">List Artikel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-7">
                    <h4 class="mb-5 fw-bolder text-gray-800">Detail</h4>
                    <div class="col-auto">
                        <label class="required fs-6 fw-bold mb-2">Toko</label>
                        <input type="text" class="form-control w-200px" name="store" v-model="tempData.store" placeholder="Nama toko" />
                    </div>
                    <div class="col-auto">
                        <label class="required fs-6 fw-bold mb-2">Nama</label>
                        <input disabled type="text" class="form-control w-200px" name="name" v-model="tempData.name" />
                    </div>
                    <div class="col-auto">
                        <label class="required fs-6 fw-bold mb-2">Kategori</label>
                        <input disabled type="text" class="form-control w-200px" name="category" v-model="tempData.category" />
                    </div>
                </div>
                <div class="row g-5 mb-7">
                    <h4 class="mb-5 fw-bolder text-gray-800">Atur Harga Jual</h4>
                    <div class="row col-auto">
                        <div class="col-auto" v-if="user_data.idStore == 1">
                            <label class="required fs-6 fw-bold mb-2">HPP (IDR)</label>
                            <input disabled type="text" class="form-control w-200px" name="hpp" v-model="tempData.hpp" placeholder="Input HPP" required />
                        </div>
                        <div class="col-auto">
                            <label class="required fs-6 fw-bold mb-2">Harga Jual (IDR)</label>
                            <input type="text" class="form-control w-200px" name="hpp" v-model="tempData.price" placeholder="Input harga jual" required />
                        </div>
                        <div class="col-auto">
                            <label class="required fs-6 fw-bold mb-2">Kenaikan Harga Jual (%)</label>
                            <input :disabled="tempData.price > 0" type="text" class="form-control w-200px" name="profit" v-model="tempData.profit" placeholder="Kenaikan harga jual" required />
                        </div>
                        <div class="col-auto">
                            <label class="fs-6 fw-bold mb-2">Harga Final</label>
                            <input disabled type="text" class="form-control w-200px" name="final_price" :value="formatIDR(finalPrice, { prefix: 'IDR ' })" placeholder="Input harga final" required />
                        </div>
                    </div>
                </div>
                <div class="row g-5 mb-7" v-if="tempData.sku.length > 0">
                    <h4 class="mb-5 text-gray-700">Ukuran Tersedia {{ tempData.sku.length > 0 ? ' ('+tempData.sku[0].artikel+')' : ''}}</h4>
                    <div class="row p-0 m-0 g-5">
                        <div class="col-auto m-0 row g-5 mb-4" v-for="(item, i) in tempData.sku" :key="i">
                            <div class="col-auto p-0 m-0 align-self-center">
                                <label class="fw-bold">{{ item.size }} <i>({{ item.sku }})</i> :</label>
                            </div>
                            <div class="col-auto mt-0">
                                <input @change="countQtyPrint()" type="text" class="form-control w-75px" name="artikel_qty" v-model="tempData.sku[i].qty" placeholder="Qty" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="py-5 fw-bold">
                    <ul class="p-0" style="list-style: none;">
                        <li><i class="text-gray-600">* Pastikan artikel sudah dipilih</i></li>
                        <li><i class="text-gray-600">* Isi jumlah qty sesuai ukuran yang akan di print</i></li>
                    </ul>
                </div>

                <div class="row justify-content-center">
                    <div class="col-auto"> 
                        <button type="button" @click="removeAllItem" class="btn btn-danger">Reset</button>
                    </div>
                    <div class="col-auto">
                        <button type="button" @click="onSubmit" class="btn btn-primary">Submit</button>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" :class="tab == 'desain' ? 'show active' : ''" id="desain" role="tabpanel">
                <div class="form w-100">
                    <div class="mb-7">
                        <h4 class="mb-5 text-gray-700">Cari Folder/Map</h4>
                        <div class="fv-row">
                            <el-select
                              name="folder_id"
                              v-model="tempData.folder_id"
                              class="form-control p-0 border-0 w-225px"
                              placeholder="Pilih folder/map"
                              size="large"
                              clearable
                              @change="getDesainByFolder($event)"
                            >
                              <el-option
                                v-for="(item, i) in listFolder"
                                :key="i"
                                :label="item.name"
                                :value="item.id"
                              />
                            </el-select>
                        </div>
                    </div>
                    <div class="mb-7">
                        <h4 class="mb-5 text-gray-700">List Desain ID</h4>
                        <div class="row p-0 mx-0 ge-5 mb-7">
                            <div class="row col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-7" v-for="(item, i) in tempData.desain" :key="i">
                                <div class="col p-0 m-0">
                                      <el-popover
                                        placement="top-start"
                                        :title="item.desain_id ? item.desain_id + ' - ' + item.folder_name : ''"
                                        :width="200"
                                        trigger="hover"
                                        content="Not found"
                                      >
                                        <template #reference>
                                            <input @change="validateDesain" @keyup="updateLocalDesain" type="text" class="form-control" name="desain_id" v-model="item.desain_id" placeholder="Desain ID" />
                                        </template>
                                        <img v-if="item.desain_id" class="w-100 cursor-pointer" :src="img_url_desain+item.image" />
                                      </el-popover>

                                    <div class="fv-plugins-message-container" v-if="item.valid == false">
                                        <div class="fv-help-block" v-if="item.duplicate == true">
                                            <span>Duplicate Desain ID</span>
                                        </div>
                                        <div class="fv-help-block" v-else>
                                            <span>Desain ID is invalid</span>
                                        </div>
                                    </div>
                                    <div class="fv-plugins-message-container" v-if="item.image">
                                        <div class="fv-help-block">
                                            <img src="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col mt-0">
                                    <input type="text" class="form-control" name="desain_qty" v-model="item.qty" placeholder="Qty" />
                                    <div class="fv-plugins-message-container" v-if="!item.qty">
                                        <div class="fv-help-block">
                                            <span>Qty is required</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto mt-0 me-7">
                                    <button @click="removeDesain(i)" class="btn btn-danger btn-sm px-4">
                                        <i class="fas fa-trash p-0"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-7">
                        <div class="col-auto">
                            <button type="button" @click="addDesain" class="btn btn-light">Tambah</button>
                        </div>
                        <div class="col-auto"> 
                            <button type="button" @click="removeAllDesain" class="btn btn-danger">Reset</button>
                        </div>
                    </div>
                    <div class="row mb-7 text-center justify-content-center">
                        <div class="col-auto">
                            <button type="button" class="btn btn-primary" :data-kt-indicator="loadingSubmit ? 'on' : 'off'" :disabled="!validDesainForm" @click="onSubmit">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" :class="tab == 'display' ? 'show active' : ''" id="display" role="tabpanel">
                <div class="mb-7">
                    <h4 class="mb-7 text-gray-700">Cari Display</h4>
                    <div class="row g-5">
                        <label class="required fs-6 fw-bold mb-2">Artikel</label>
                        <div class="row">
                            <div class="col-auto">
                                <input type="text" class="form-control w-200px" name="artikel" v-model="tempData.artikel" placeholder="Input artikel" v-on:keyup.enter="getDetailArtikelDisplay()" />
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-light-primary" @click="getDetailArtikelDisplay()">Cari</button>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-light-primary" @click="getArtikelDisplay(), pagination.page = 1">List Artikel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-5 mb-7" v-if="tempData.display.length > 0">
                    <h4 class="mb-5 text-gray-700">List Display</h4>
                    <div class="row p-0 m-0 g-5">
                        <div class="col-auto m-0 row g-5 mb-4" v-for="(item, i) in tempData.display" :key="i">
                            <div class="col-auto p-0 m-0">
                                <el-popover
                                    placement="top-start"
                                    :title="item.desain_id ? item.desain_id + ' - ' + item.folder : ''"
                                    :width="200"
                                    trigger="hover"
                                    content="Not found"
                                >
                                    <template #reference>
                                        <input @change="validateDisplay" type="text" class="form-control" name="sku" v-model="item.sku" placeholder="SKU" />
                                    </template>
                                    <img v-if="item.desain_id" class="w-100 cursor-pointer" :src="img_url_desain+item.image" />
                                </el-popover>

                                <div class="p-1 text-gray-600">{{ item.raw_category + ' - ' + item.color + ' - ' + item.size }}</div>

                                <div class="fv-plugins-message-container" v-if="item.valid == false">
                                    <div class="fv-help-block" v-if="item.duplicate == true">
                                        <span>Duplicate SKU</span>
                                    </div>
                                    <div class="fv-help-block" v-else>
                                        <span>SKU is invalid</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto mt-0">
                                <input @change="countQtyPrint()" type="text" class="form-control w-75px" name="artikel_qty" v-model="tempData.display[i].qty" placeholder="Qty" />
                                <div class="fv-plugins-message-container" v-if="!item.qty">
                                    <div class="fv-help-block">
                                        <span>Qty is required</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto mt-0 me-7">
                                <button @click="removeDisplay(i)" class="btn btn-danger btn-sm px-4">
                                    <i class="fas fa-trash p-0"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-7">
                    <div class="col-auto">
                        <button type="button" @click="addDisplay" class="btn btn-light">Tambah</button>
                    </div>
                    <div class="col-auto"> 
                        <button type="button" @click="removeAllDisplay" class="btn btn-danger">Reset</button>
                    </div>
                </div>

                <div class="py-5 fw-bold">
                    <ul class="p-0" style="list-style: none;">
                        <li><i class="text-gray-600">* Pastikan artikel sudah dipilih</i></li>
                        <li><i class="text-gray-600">* Isi jumlah qty sesuai ukuran yang akan di print</i></li>
                    </ul>
                </div>

                <div class="row justify-content-center">
                    <div class="col-auto">
                        <button type="button" @click="onSubmit" class="btn btn-primary">Submit</button>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog v-model="modal" :title="'List Artikel'" :close-on-click-modal="true" width="800px">
            <div class="filter position-relative mb-4 fs-6 fw-bolder text-gray-600" ref="searchRef">
                <div class="filter-item">
                    <span>KATEGORI</span>
                    <el-select v-model="filter.category_id" placeholder="Pilih Kategori" clearable @change="onFilter('product')">
                        <el-option v-for="(o, i) in listCategory" :key="i" :value="o.id" :label="o.name"/>
                    </el-select>
                </div>
                <div class="filter-item">
                    <span>Search</span>
                    <el-input v-model="filter.search" placeholder="Search" clearable />
                </div>
                <div class="d-flex">
                    <button class="btn btn-icon btn-light-primary me-3" @click="onFilter()"><i class="fas fa-search"></i></button>
                    <button class="btn btn-icon btn-light-danger" @click="resetFilter()"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="my-3 p-0 text-gray-600 text-uppercase fw-bolder" v-if="filterActive">
                <span>Hasil filter</span>
            </div>
            <div>
                <table class="table table-rounded table-row-dashed table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th>#</th>
                            <th>Kategori</th>
                            <th>Artikel</th>
                            <th>Warna</th>
                            <th>Ukuran</th>
                            <th class="text-end">Vendor</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in listArtikel" :key="i" class="cursor-pointer" title="Pilih artikel" @click="getDetailArtikel(item.artikel)">
                            <td scope="row">{{ item.index }}</td>
                            <td>{{ item.raw_category }}</td>
                            <td>{{ item.artikel }}</td>
                            <td>{{ item.color }}</td>
                            <td>{{ item.size && item.size.toString().replaceAll(',', ', ') }}</td>
                            <td class="text-end">{{ item.nama_vendor }}</td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
            <template #footer>
                <div class="text-center">
                    <el-pagination background layout="prev, pager, next" :page-count="pagination.total_page" :pager-count="4" @next-click="handlePageChange" @prev-click="handlePageChange" @current-change="handlePageChange" v-model:current-page="pagination.page" />
                </div>
            </template>
        </el-dialog>

        <el-dialog v-model="modalDisplay" :title="'List Artikel Display'" :close-on-click-modal="true" width="800px">
            <div class="filter position-relative mb-4 fs-6 fw-bolder text-gray-600" ref="searchRef">
                <div class="filter-item">
                    <span>KATEGORI</span>
                    <el-select v-model="filter.category_id" placeholder="Pilih Kategori" clearable @change="onFilter('product')">
                        <el-option v-for="(o, i) in listCategory" :key="i" :value="o.id" :label="o.name"/>
                    </el-select>
                </div>
                <div class="filter-item">
                    <span>FOLDER</span>
                    <el-select v-model="filter.folder_id" placeholder="Pilih Folder" clearable @change="onFilter('product')">
                        <el-option v-for="(o, i) in listFolder" :key="i" :value="o.id" :label="o.name">
                            <span style="float: left">{{ o.name }}</span>
                        </el-option>
                    </el-select>
                </div>
                <div class="filter-item">
                    <span>Search</span>
                    <el-input v-model="filter.search" placeholder="Search" clearable />
                </div>
                <div class="d-flex">
                    <button class="btn btn-icon btn-light-primary me-3" @click="onFilter()"><i class="fas fa-search"></i></button>
                    <button class="btn btn-icon btn-light-danger" @click="resetFilter()"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="my-3 p-0 text-gray-600 text-uppercase fw-bolder" v-if="filterActive">
                <span>Hasil filter</span>
            </div>
            <div>
                <table class="table table-rounded table-row-dashed table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th>#</th>
                            <th>Kategori</th>
                            <th>Artikel</th>
                            <th>Desain ID</th>
                            <th>Folder</th>
                            <th>Warna</th>
                            <th>Ukuran</th>
                            <th class="text-end">Vendor</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in listArtikelDisplay" :key="i" class="cursor-pointer" title="Pilih artikel" @click="getDetailArtikelDisplay(item.artikel)">
                            <td scope="row">{{ item.index }}</td>
                            <td>{{ item.raw_category }}</td>
                            <td>{{ item.artikel }}</td>
                            <td>{{ item.desain_id }}</td>
                            <td>{{ item.folder }}</td>
                            <td>{{ item.color }}</td>
                            <td>{{ item.size && item.size.toString().replaceAll(',', ', ') }}</td>
                            <td class="text-end">{{ item.nama_vendor }}</td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
            <template #footer>
                <div class="text-center">
                    <el-pagination background layout="prev, pager, next" :page-count="pagination.total_page" :pager-count="4" @next-click="handlePageChange" @prev-click="handlePageChange" @current-change="handlePageChange" v-model:current-page="pagination.page" />
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<style scoped lang="scss">
$barcode_height: 94px;
$barcode_width: 188.976377953px;
$barcode_margin: 4px;
$barcode_padding: 8px;

.barcode-wrapper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-print-color-adjust: exact !important;
    max-height: 300px;
    overflow: auto;

    .barcode-list {
        margin: $barcode_margin;

        .barcode-content {
            // 50mm x 25mm
            height: calc($barcode_height - $barcode_margin);
            padding: $barcode_padding;
            font-family: 'Work Sans', sans-serif !important;

            .barcode {
                >img {
                    width: 100%;
                    height: 100%;
                }
            }

            span {
                font-size: 8.5px;
                font-weight: 600 !important;
            }

            .data-highlight {
                border-radius: 2px;
                padding: .5px 4px;
                min-width: 25px !important;
                display: inline-block;
                text-align: center;
            }
        }
    }
}

.barcode-wrapper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-print-color-adjust: exact !important;
    max-height: 300px;
    overflow: auto;

    .barcode-content {
        height: calc($barcode_height - $barcode_margin); // 25mm
        width: calc($barcode_width - $barcode_margin); // 50mm
        display: flex;
        flex-direction: column;
        padding: $barcode_padding;
        font-family: 'Work Sans', sans-serif !important;
        margin: 0.5rem;
        border-radius: 10px;

        >div:not(:last-child) {
            margin-bottom: 1px !important;
        }

        .barcode {
            >img {
                width: 100%;
                height: 100%;
            }
        }

        span {
            font-size: 8.5px;
            font-weight: 600 !important;
        }

        .data-highlight {
            border-radius: 2px;
            padding: .5px 4px;
            min-width: 25px !important;
            display: inline-block;
            text-align: center;
        }
    }
}

canvas {
    width: 100% !important;
    height: 100% !important;
}
</style>
<script>
import { defineComponent, onBeforeMount,onMounted, ref, reactive, watchEffect, watch, computed, onUpdated, provide } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'
import { ElLoading, Elpagination } from 'element-plus'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
    components: {
        //Datatable,
        //Field,
        //Form,
        //ErrorMessage,
        //'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const store = useStore();

        const img_url_product = ref(process.env.VUE_APP_IMAGE_PRODUCTS)
        const img_url_desain = ref(process.env.VUE_APP_IMAGE_DESAINS)

        const tab = ref('item')

        const user_data = computed(() => {
            return store.getters['currentUser'];
        })

        const data = reactive([])
        const listArtikel = reactive([])
        const listArtikelDisplay = reactive([])
        const listCategory = computed(() => store.getters.getListCategory)
        const listFolder = computed(() => store.getters.getListRawFolder)

        const tempData = reactive({
            artikel: null,
            store: null,
            name: '',
            category: '',
            hpp: null,
            price: null,
            sku: [],
            desain: [],
            display: [],
            folder_id: null,
            profit: 0,
        })

        const dataToPrint = reactive([])

        const modal = ref(false)
        const modalDisplay = ref(false)
        const loadingTable = ref(false)
        const loadingSubmit = ref(false)

        const router = useRouter()

        const filter = reactive({
            search: null,
            category_id: null,
            folder_id: null,
        })
        const filterActive = ref(false)

        const finalPrice = computed(() => {
            let setPrice = parseInt(tempData.price) ?
                parseInt(tempData.price) + ((parseInt(tempData.price) / 100) * parseInt(tempData.profit)) :
                parseInt(tempData.hpp) + ((parseInt(tempData.hpp) / 100) * parseInt(tempData.profit));

            return setPrice
        })

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

        const getArtikel = async () => {
            try {
                modal.value = true
                loadingTable.value = true
                listArtikel.splice(0)
                let tipe_produk = '';

                let page = pagination.value.page ? '?page=' + pagination.value.page : '?page=1';
                let category = filter.category_id ? '&category=' + filter.category_id : '';
                let brand = filter.brand_id ? '&brand=' + filter.brand_id : '';
                let search = filter.search ? '&search=' + filter.search : '';

                if(tab.value == 'display') {
                    tipe_produk = '&tipe_produk=DISPLAY';
                }

                await ApiService.get("product_raw/list_artikel" + page +  tipe_produk + category + brand + search)
                    .then(({ data }) => {
                        pagination.value = data.data.pagination
                        Object.assign(listArtikel, data.data.data)
                    })
                    .catch(({ response }) => {
                        throw new Error('Error getting list artikel');
                    });

            } catch (e) {
                Swal.fire({
                    title: e,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loadingTable.value = false
        }

        const getDetailArtikel = async (val = null) => {
            loadingTable.value = true;

            try {
                let artikel = '';

                val == null ?
                    artikel = tempData.artikel :
                    artikel = val, tempData.artikel = artikel;

                if (!artikel) {
                    throw new Error('Input artikel is empty')
                }

                await ApiService.get("product_raw/detail_artikel/" + artikel.toUpperCase())
                    .then(async (res) => {
                        if (res.data.error) {
                            throw(res.data.messages)
                        }

                        Object.assign(data, res.data)
                        tempData.hpp = res.data.data[0].hpp
                        tempData.price = res.data.data[0].price
                        tempData.name = res.data.data[0].name
                        tempData.category = res.data.data[0].raw_category

                        // Reset list
                        tempData.sku.splice(0)

                        for (let i = 0; i < res.data.data.length; i++) {
                            res.data.data[i]['qty'] = null;
                            tempData.sku.push(res.data.data[i]);
                        }
                    })
                    .catch(({ response }) => {
                        Swal.fire({
                            title: response.data.messages.error,
                            icon: "error",
                            buttonsStyling: false,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    });

                modal.value = false

            } catch (e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loadingTable.value = false
        }

        const getArtikelDisplay = async () => {
            try {
                loadingTable.value = true
                modalDisplay.value = true;

                listArtikelDisplay.splice(0)

                let page = pagination.value.page ? '?page=' + pagination.value.page : '?page=1';
                let category = filter.category_id ? '&category=' + filter.category_id : '';
                let folder = filter.folder_id ? '&folder=' + filter.folder_id : '';
                let search = filter.search ? '&search=' + filter.search : '';

                await ApiService.get("artikel_display" + page + category + folder + search)
                    .then(({ data }) => {
                        pagination.value = data.data.pagination
                        Object.assign(listArtikelDisplay, data.data.data)
                    })
                    .catch(({ response }) => {
                        throw new Error('Error getting list artikel');
                    });

            } catch (e) {
                Swal.fire({
                    title: e,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loadingTable.value = false
        }

        const getDetailArtikelDisplay = async (val = null) => {
            const loading = ElLoading.service({ text: 'Tunggu', })

            try {
                let artikel = '';

                val == null ?
                    artikel = tempData.artikel :
                    artikel = val, tempData.artikel = artikel;

                if (!artikel) {
                    throw new Error('Input artikel is empty')
                }

                await ApiService.get("artikel_display_detail/" + artikel.toUpperCase())
                    .then(async (res) => {
                        if (res.data.error) {
                            throw(res.data.messages)
                        }

                        Object.assign(data, res.data)

                        for (let i = 0; i < res.data.data.length; i++) {
                            res.data.data[i]['qty'] = null;
                            tempData.display.push(res.data.data[i]);
                        }
                        
                        localStorage.setItem(process.env.VUE_APP_TEMP_BARCODE_DISPLAY, JSON.stringify(tempData.display))
                    })
                    .catch(({ response }) => {
                        Swal.fire({
                            title: response.data.messages.error,
                            icon: "error",
                            buttonsStyling: false,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    });

                modalDisplay.value = false
                validateDisplay();

            } catch (e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loading.close()
        }

        const onFilter = async (type) => {
            filterActive.value = false
            let data = {}

            if(tab.value == 'item') {
                await getArtikel()
            }

            if(tab.value == 'display') {
                await getArtikelDisplay()
            }

            currentPage.value = 1

            Object.values(filter).every(o => o == null || o.length == 0) ?
                filterActive.value = false :
                filterActive.value = true
        }

        const resetFilter = async (type) => {
            Object.keys(filter).map(k => filter[k] = null) 

            filterActive.value = false

            if(tab.value == 'item') {
                await getArtikel()
            }

            if(tab.value == 'display') {
                await getArtikelDisplay()
            }
        }

        const currentPage = ref(1)
        const totalPage = ref(1)

        const handlePageChange = async (page) => {
            if(tab.value == 'item') {
                await getArtikel()
            }
            if(tab.value == 'display') {
                await getArtikelDisplay()
            }
        }

        const countQtyPrint = () => {
            const loading = ElLoading.service({ text: 'Tunggu', })

            try {
                dataToPrint.splice(0) // reset value

                if (tab.value == 'item') {
                    let array = JSON.parse(JSON.stringify(tempData.sku))

                    array.forEach((o, i) => {
                        dataToPrint.push(Array.from({ length: o.qty }).fill(o))
                    })

                } else if(tab.value == 'desain') {
                    let array = JSON.parse(JSON.stringify(tempData.desain))

                    array.forEach((o, i) => {
                        dataToPrint.push(Array.from({ length: o.qty }).fill(o))
                    })
                } else if(tab.value == 'display') {
                    let array = JSON.parse(JSON.stringify(tempData.display))

                    array.forEach((o, i) => {
                        dataToPrint.push(Array.from({ length: o.qty }).fill(o))
                    })

                } else {
                    throw new Error('Type not valid')
                }

            } catch (e) {
                console.log(e)
            }

            loading.close()
        }

        const removeAllItem = () => {
            try {
                tempData.sku.splice(0)
                tempData.name = null
                tempData.price = null
                tempData.hpp = null
                tempData.store = null
                
                Swal.fire({
                    title: 'Berhasil menghapus semua SKU',
                    icon: "success",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })

            } catch (e) {
                console.log(e)
                Swal.fire({
                    title: 'Gagal menghapus semua SKU',
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        }

        const updateLocalDesain = () => {
            localStorage.setItem(process.env.VUE_APP_TEMP_BARCODE_DESAIN, JSON.stringify(tempData.desain))
        }

        const addDesain = () => {
            try {
                let checkLastArray = tempData.desain[tempData.desain.length - 1];

                if (tempData.desain.length > 1 && Object.keys(checkLastArray).some(k => checkLastArray[k] == null)) {
                    throw new Error("Harap lengkapi form isian")
                }

                if (tempData.desain.some(o => o.valid == false)) {
                    throw new Error("Beberapa Desain ID tidak valid")
                }

                let data = {
                    desain_id: null,
                    qty: null,
                    valid: true,
                }

                tempData.desain.push(data)
                localStorage.setItem(process.env.VUE_APP_TEMP_BARCODE_DESAIN, JSON.stringify(tempData.desain))

            } catch (e) {
                console.log(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

        }

        const removeDesain = (index) => {
            try {
                tempData.desain.splice(index, 1)
                localStorage.setItem(process.env.VUE_APP_TEMP_BARCODE_DESAIN, JSON.stringify(tempData.desain))

            } catch (e) {
                console.log(e)
            }
        }

        const removeAllDesain = () => {
            try {
                tempData.desain.splice(0)
                tempData.folder_id = null
                localStorage.setItem(process.env.VUE_APP_TEMP_BARCODE_DESAIN, JSON.stringify(tempData.desain))
                
                Swal.fire({
                    title: 'Berhasil menghapus semua desain',
                    icon: "success",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })

            } catch (e) {
                console.log(e)
                Swal.fire({
                    title: 'Gagal menghapus semua desain',
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        }

        const validDesainForm = ref(false);
        const validDisplayForm = ref(false);

        const validateDesain = async () => {
            const loading = ElLoading.service({
                lock: true,
                text: 'Validating...',
                background: 'rgba(255,255,255,0.7)'
            })

            try {
                let items = JSON.parse(JSON.stringify(tempData.desain))

                await ApiService.post('product_raw/validate_desain_barcode', { data: items })
                    .then((response) => {
                        if (response.data.error) {
                            throw new Error('Response was error')
                        }

                        Object.assign(tempData.desain, response.data.data)
                        localStorage.setItem(process.env.VUE_APP_TEMP_BARCODE_DESAIN, JSON.stringify(tempData.desain))

                        countQtyPrint()
                    })
                    .catch((error) => {
                        throw new Error(error)
                    })

            } catch (e) {
                console.log(e)
            }

            loading.close()
        }

        const validateDisplay= async () => {
            const loading = ElLoading.service({
                lock: true,
                text: 'Validating...',
                background: 'rgba(255,255,255,0.7)'
            })

            try {
                let items = JSON.parse(JSON.stringify(tempData.display))

                await ApiService.post('product_raw/validate_display_barcode', { data: items })
                    .then((response) => {
                        if (response.data.error) {
                            throw new Error('Response was error')
                        }

                        Object.assign(tempData.display, response.data.data)
                        localStorage.setItem(process.env.VUE_APP_TEMP_BARCODE_DISPLAY, JSON.stringify(tempData.display))

                        countQtyPrint()
                    })
                    .catch((error) => {
                        throw new Error(error)
                    })

            } catch (e) {
                console.log(e)
            }

            loading.close()
        }

        const getDesainByFolder = async(folder_id) => {
            const loading = ElLoading.service({
                lock: true,
                text: 'Validating...',
                background: 'rgba(255,255,255,0.7)'
            })

            try {
                tempData.desain.splice(0)

                if(folder_id) {
                    await ApiService.get('product_raw/folder_raw_desain/'+folder_id)
                    .then((response) => {
                        if (response.data.error) {
                            throw new Error('Response was error')
                        }

                        Object.assign(tempData.desain, response.data.data)
                        localStorage.setItem(process.env.VUE_APP_TEMP_BARCODE_DESAIN, JSON.stringify(tempData.desain))

                        countQtyPrint()
                    })
                    .catch((error) => {
                        throw new Error(error)
                    })

                }

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loading.close()
        }

        const addDisplay = () => {
            try {
                let checkLastArray = tempData.display[tempData.display.length - 1];
                if (tempData.display.length > 1 && Object.keys(checkLastArray).some(k => checkLastArray[k] == null)) {
                    throw new Error("Harap lengkapi form isian")
                }

                if (tempData.display.some(o => o.valid == false)) {
                    throw new Error("Beberapa display tidak valid")
                }

                let data = {
                    desain_id: null,
                    folder: null,
                    size: null,
                    sku: null,
                    color: null,
                    raw_category: null,
                    image: null,
                    qty: null,
                    valid: true,
                }

                tempData.display.push(data)
                localStorage.setItem(process.env.VUE_APP_TEMP_BARCODE_DISPLAY, JSON.stringify(tempData.display))

            } catch (e) {
                console.log(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

        }

        const removeDisplay = (index) => {
            try {
                tempData.display.splice(index, 1)
                localStorage.setItem(process.env.VUE_APP_TEMP_BARCODE_DISPLAY, JSON.stringify(tempData.display))

            } catch (e) {
                console.log(e)
            }
        }

        const removeAllDisplay = () => {
            try {
                tempData.display.splice(0)
                tempData.folder_id = null
                localStorage.setItem(process.env.VUE_APP_TEMP_BARCODE_DISPLAY, JSON.stringify(tempData.display))
                
                Swal.fire({
                    title: 'Berhasil menghapus semua display',
                    icon: "success",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })

            } catch (e) {
                console.log(e)
                Swal.fire({
                    title: 'Gagal menghapus semua display',
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        }

        const onSubmit = async () => {
            try {
                loadingSubmit.value = true

                if (tab.value == 'item') {
                    let values = { data: JSON.parse(JSON.stringify(dataToPrint)), price: finalPrice.value, store: tempData.store, type: tab.value }

                    let newValues = values.data.filter((o, i) => {
                        return o.length > 0
                    }).map(e => e).flat()

                    values.data = await newValues

                    if(values.data.length == 0 || !tempData.name || !tempData.price || !tempData.store) {
                        throw new Error('Data tidak boleh kosong')
                    } 

                    await store.dispatch(Actions.ADD_RAW_BARCODE, values)

                } else if (tab.value == 'desain') {
                    if (!validDesainForm.value) {
                        throw new Error('Submit gagal, silakan cek form kembali')
                    }
                    
                    await validateDesain()

                    let values = { data: JSON.parse(JSON.stringify(dataToPrint)), store: tempData.store, type: tab.value }

                    let newValues = values.data.filter((o, i) => {
                        return o.length > 0
                    }).map(e => e).flat()

                    values.data = await newValues
                    await store.dispatch(Actions.ADD_RAW_BARCODE, values)

                    localStorage.removeItem(process.env.VUE_APP_TEMP_BARCODE_DESAIN)
                
                } else if (tab.value == 'display') {
                    if (!validDisplayForm.value) {
                        throw new Error('Submit gagal, silakan cek form kembali')
                    }

                    await validateDisplay()

                    console.log(dataToPrint)

                    let values = { data: JSON.parse(JSON.stringify(dataToPrint)), type: tab.value }

                    let newValues = values.data.filter((o, i) => {
                        return o.length > 0
                    }).map(e => e).flat()

                    values.data = await newValues
                    await store.dispatch(Actions.ADD_RAW_BARCODE, values)

                    localStorage.removeItem(process.env.VUE_APP_TEMP_BARCODE_DISPLAY)

                } else {
                    throw new Error('Submit gagal, silakan hubungi developer')
                }

                const routeData = router.resolve({ path: '/produksi/barcode_generator/print_barcode' });

                window.open(routeData.href, "_blank").focus();

            } catch (e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loadingSubmit.value = false
        }

        onBeforeMount(() => {
            setCurrentPageBreadcrumbs("Raw Barcode Generator", ["Raw Barcode Generator"]);

            store.dispatch(Actions.LIST_CATEGORY);
            store.dispatch(Actions.LIST_RAW_FOLDER);

            if (localStorage.getItem(process.env.VUE_APP_TEMP_BARCODE_DESAIN) && JSON.parse(localStorage.getItem(process.env.VUE_APP_TEMP_BARCODE_DESAIN)).length > 0) {
                tempData.desain = JSON.parse(localStorage.getItem(process.env.VUE_APP_TEMP_BARCODE_DESAIN));
                //validateDesain()
            }

            if (localStorage.getItem(process.env.VUE_APP_TEMP_BARCODE_DISPLAY) && JSON.parse(localStorage.getItem(process.env.VUE_APP_TEMP_BARCODE_DISPLAY)).length > 0) {
                //tempData.display = JSON.parse(localStorage.getItem(process.env.VUE_APP_TEMP_BARCODE_DISPLAY));
                //validateDisplay()
            }
        })

        watch(() => tempData.price, (val) => {
            // Reset profit
            if (val > 0) {
                tempData.profit = 0;
            }

        })

        watch(() => tempData.desain, (val) => {
            if (val) {
                let validate = val.every(o => o.valid == true && o.desain_id && o.qty)
                validDesainForm.value = validate
            }
        }, { deep: true })

        watch(() => tempData.display, (val) => {
            if (val) {
                let validate = val.every(o => o.valid == true && o.desain_id && o.qty)
                validDisplayForm.value = validate
            }
        }, { deep: true })

        return {
            router,
            user_data,
            modal,
            loadingTable,
            loadingSubmit,
            img_url_desain,
            img_url_product,
            validDesainForm,
            tab,
            data,
            dataToPrint,
            listArtikel,
            listCategory,
            listFolder,
            getDetailArtikel,
            getArtikel,
            tempData,
            filter,
            onFilter,
            resetFilter,
            countQtyPrint,
            finalPrice,
            removeAllItem,
            addDesain,
            removeDesain,
            removeAllDesain,
            addDisplay,
            removeDisplay,
            removeAllDisplay,
            updateLocalDesain,
            validateDesain,
            getDesainByFolder,
            onSubmit,
            currentPage,
            totalPage,
            handlePageChange,
            formatIDR,
            pagination,
            listArtikelDisplay,
            modalDisplay,
            getArtikelDisplay,
            getDetailArtikelDisplay,
            validateDisplay,
        }
    }
})
</script>